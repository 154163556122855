<template>
    <div class="main-wrapper">
        <div class="admin-top">
            <div class="top-left">
                <span class="left-title">分类管理</span>
            </div>
            <div class="top-right">
                <el-button type="primary" @click="addClassifies()">添加分类</el-button>
            </div>
        </div>

        <el-table
                :data="classList"
                style="width: 100%; margin-top: 20px; flex: 1;"
                height="1%"
                size="medium"
                row-key="id"
                default-expand-all
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa',}" :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column label="操作" align="center" width="200px">
                <template slot-scope="scope">
                    <el-button size="small" type="text" v-if="scope.row.super_id === 0" @click="addsubclass(scope.row)">添加子分类</el-button>
                    <el-button size="small" type="text" @click="editCollege(scope.row)">编辑</el-button>
                    <el-button size="small" type="text" @click="deleteCollege(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
                v-if="classList.length>0"
                style="margin-bottom: 10px"
                class="pages-center"
                :current-page="adminPages.currentPageNum"
                :page-size="adminPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="adminPages.total"
                @current-change="adminCurrentChange"
        ></el-pagination>
    </div>
</template>

<script>
    export default {
        name: "HelpClassifiesManage",
        data(){
            return {
                classList:[],
                classChildrenList:[],
                cijiList:[],
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                //添加分类弹窗标题
                classTitleType: '添加分类',
                //是否显示弹窗
                dialogAddHelpClass: false,
                //添加分类表单
                addHelpClassForm: {

                },
            }
        },
        created(){
            this.getClassList();
        },
        methods:{
            getRowKey(row) {
                return row.id;
            },
            //获取分类
            getClassList(){
                let params = {
                    paging:1,
                    page: this.adminPages.currentPageNum,
                    pageSize:this.adminPages.eachPageNum
                };
                this.$http.axiosGetBy(this.$api.helpClassification, params ,(res)=>{
                    if(res.code === 200){
                        this.classList = res.data.data;
                        this.adminPages.total = res.data.total;
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //分页
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                this.getClassList()
            },
            //添加分类
            addClassifies(){
                let that = this;
                that.$prompt('分类名称','添加分类',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value })=>{
                    let formData = new FormData();
                    formData.append('name',value);
                    that.$http.axiosPost(that.$api.helpClassification,formData,(res)=>{
                        if (res.code === 200) {
                            that.getClassList();
                        } else {
                            that.$message.error(res.message);
                        }
                    }, (err) => {
                        console.log(err);
                    });
                    that.$message({
                        type: 'success',
                        message:'添加'+ value + '成功'
                    })
                }).catch(()=>{
                    that.$message({
                        type:'info',
                        message:'取消添加'
                    })
                })
            },
            //编辑分类
            editCollege(row){
                let that = this;
                const name = row.name;
                const id = row.id;
                that.$prompt('分类名称','编辑分类',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPlaceholder: name
                }).then(({value})=>{
                    let formData = new FormData();
                    formData.append('id',id);
                    formData.append('name',value);
                    if (row.super_id) {
                        formData.append('super_id',row.super_id);
                    }
                    that.$http.axiosPost(that.$api.helpClassification,formData,(res)=>{
                        if(res.code === 200){
                            this.$message.success(res.message);
                            that.getClassList();
                        }else{
                            this.$message.warning(res.message)
                        }
                    },(err)=>{
                        console.log(err)
                    });
                }).catch(()=>{
                    that.$message({
                        type:'info',
                        message:'取消编辑'
                    })
                })
            },
            //删除分类
            deleteCollege(row){
                let that = this;
                const id = row.id;
                that.$confirm('删除分类后不能恢复，确定要删除吗？','删除分类',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=>{
                    let formData = new FormData();
                    formData.append('id',Number(row.id));
                    that.$http.axiosPost(that.$api.help_delete_classification,formData,(res)=>{
                        if(res.code === 200){
                            that.$message({
                                type: 'success',
                                message:'删除成功'
                            })
                            that.getClassList();
                        } else {
                            this.$message.error(res.message);
                        }
                    })

                }).catch(()=>{
                    that.$message({
                        type: 'info',
                        message:'已取消删除'
                    })
                })
            },
            //添加子分类
            addsubclass(row){
                let that = this;
                const super_id = row.id;
                that.$prompt('分类名称','添加子分类',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value })=>{
                    let formData = new FormData();
                    formData.append('name',value);
                    formData.append('super_id',super_id);
                    that.$http.axiosPost(that.$api.helpClassification,formData,(res)=>{
                        if (res.code === 200) {
                            that.getClassList();
                        } else {
                            that.$message.error(res.message);
                        }
                    }, (err) => {
                        console.log(err);
                    });
                    that.$message({
                        type: 'success',
                        message:'添加'+ value + '成功'
                    })
                }).catch(()=>{
                    that.$message({
                        type:'info',
                        message:'取消添加'
                    })
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
        background-color: transparent;
    }
    ::v-deep.el-table--medium td, ::v-deep.el-table--medium th{
        padding: 12px 9px;
    }
    .demo-table-expand{
        border-bottom: 1px solid #F3F5F7;
        height: 57px!important;
        padding-left: 260px;
        line-height: 57px;
        position: relative;
        margin: 0!important;
    }
    .el-table__expanded-cell .demo-table-expand:last-child{
        border-bottom: none
    }
    .ejbtn1{
        position: absolute;
        right: 250px;
        top: 8px;
    }
    .ejbtn2{
        position:absolute;
        right: 210px;
        top: 8px;
    }
</style>